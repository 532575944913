
.ArticleContent-item-remove-button {
	position: absolute;
	top: 1px;
	right: 3px;
    z-index: 150;
}

.ArticleContent-item-remove-button:hover {
    color: #ffa8b0;
}

.ArticleContent-item {
    position: absolute;
	border: lightgrey 1px solid;
    background: repeating-linear-gradient(
      135deg,
      lightgrey,
      lightgrey 1px,
      rgba(0, 0, 0, 0) 1px,
      rgba(0, 0, 0, 0) 4px
    );
}

.ArticleContent-item .col-md-12 {
    margin: 0;
}

.ArticleContent-item .FormLine .row div .FormLine-label {
	padding: 2px 10px !important;
	cursor: pointer;
}

.ArticleContent-item .FormLine .row div textarea {
	height: calc(100% - 11px);
	resize: none;
}

.ArticleContent-block-buttons {
    margin: 15px 0px;
    text-align: center;
}

.ArticleContent-layout {
    margin-left: 15px;
}

.ArticleContent-item-new {
    border: #8fddff 1px solid;
    background: repeating-linear-gradient(
        135deg,
        #8fddff,
        #8fddff 1px,
        rgba(0, 0, 0, 0) 1px,
        rgba(0, 0, 0, 0) 4px
    );
}

.ArticleContent-item-modified {
    border: #bcebff 1px solid !important;
    background: repeating-linear-gradient(
        135deg,
        #bcebff,
        #bcebff 1px,
        rgba(0, 0, 0, 0) 1px,
        rgba(0, 0, 0, 0) 4px
    ) !important;
}

.ArticleContent-image-wrapper {
    height: 100%;
}

.medium-editor-element blockquote {
    margin: 0px 10px;
    font-style: italic;
}

.ArticleContent-item .popup-content {
    width: 100% !important;
}