
#Login {
    width: 100%;
    height: 100%;
}

#Login-box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 20px);
    min-width: 300px;
    max-width: 500px;
    margin: calc(15vh + 50px) 0px 25vh 0px;
    background:
        linear-gradient(to right, black 1px, transparent 4px) 0 0,
        linear-gradient(to left, black 1px, transparent 4px) 100% 100%,
        linear-gradient(to bottom, black 1px, transparent 4px) 0 0,
        linear-gradient(to top, black 1px, transparent 4px) 100% 100% !important;
    background-repeat: no-repeat !important;
    background-size: 80px 80px !important;
    padding: 6px 20px 80px 20px;
}

#Login-inner-box {
    width: 100%;
    padding: 10px 20px;
}

#Login-area {
    position: fixed;
    width: 100%;
    height: 100%;
    min-height: 500px;
}

.Login-title-small {
    font-size: 14px;
}

#Login h1 {
    border: none !important;
}

/* BACKGROUND EFFECT */

.Login-circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.Login-circles li{
    position: absolute;
    list-style: none;
    min-width: 20px;
    height: auto;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.Login-circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.Login-circles li:nth-child(2){
    left: 15%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.Login-circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.Login-circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.Login-circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.Login-circles li:nth-child(6){
    left: 70%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.Login-circles li:nth-child(7){
    left: 5%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.Login-circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.Login-circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.Login-circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: .3;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
    }
}