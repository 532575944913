
.Tab {
}

@media (min-width: 0px) {
    .Tab-menu {
    	padding: 20px 0px;
        z-index: 0;
    }
}

@media (min-width: 768px) {
    .Tab-menu {
        position: fixed;
        padding: 20px 0px;
        margin-left: 0px;
        z-index: 0;
    }
}

.Tab-menu-el {
    position: relative;
	cursor: pointer;
	color: #010101;
    text-transform: uppercase;
    font-family: "Fjalla One", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    padding: 10px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    width: 100%;
    max-width:inherit;
}

.Tab-menu-el:hover {
	background-color: rgba(0, 159, 227, 0.05);
    color: black !important;
}

.Tab-menu-el-selected {
    background-color: #009fe3;
    color: white;
    cursor: default;
}

.Tab-menu-el-selected:hover {
    background-color: #009fe3;
    color: white !important;
}

.Tab-notification {
    position: absolute !important;
    bottom: 0px;
    right: 0px;
    color: white;
    font-size: 12px;
    height: 20px;
    line-height: 15px;
    border-radius: 20px;
    background-color: #e40613;
    padding: 3px 7px;
}