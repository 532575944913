
.FormLine {
    margin: 4px 0px;
    max-height: calc(100% - 8px);
}

.FormLine .row {
    max-height: 100%;
}

.FormLine-label {
    padding: 8px;
}

.FormLine-wrong-format {
	border: 2px solid #e40613 !important;
}

.FormLine-wrong-format:focus {
	border: 3px solid #e40613 !important;
}

.FormLine-right-format {
	border: 2px solid #bcebff !important;
}

.FormLine-right-format:focus {
	border: 3px solid #bcebff !important;
}

.FormLine-chips {
	width: 100%;
}

.Formline-image {
	max-width: 100%;
	max-height: 440px;
}

.Formline-image-wrapper {
	position: relative;
	text-align: center;
}

.Formline-image-display-wrapper {
	text-align: center;
	min-height: 500px;
	display:flex;
	align-items:center;
	justify-content:center;
}

.Formline-image-display-wrapper .NoImage {
	position: absolute;
}

.Formline-frame-wrapper {
	margin: 0px auto;
}

.Formline-frame-display-wrapper {
	text-align: center;
}

.Formline-frame-wrapper iframe {
	max-width: 100%;
	margin-top: 10px;
}

.medium-editor-toolbar-anchor-preview-inner {
	background-color: black;
	border-radius: 2em;
	padding: 10px !important;
}

.medium-editor-toolbar-form {
	padding: 3px;
	background-color:  black;
}

.medium-editor-element {
	white-space:normal !important;
}

.Formline-datetime input {
	position: absolute;
}

.Formline-datetime button {
	position: absolute;
	top: 0px;
	opacity: 0;
	border-radius: 0 !important;
	width: 100%;
	height: 40px;
	z-index: 50;
}

.FormLine div[data-disable-editing="true"] {
	background-color: rgba(239, 239, 239, 0.3) !important;
	cursor: default !important;
}

.FormLine .Formline-datetime input {
	color: grey !important;
	border: lightgrey 2px solid;
}

.FormLine .Formline-datetime input[disabled] {
	background-color: rgba(239, 239, 239, 0.3) !important;
}

.FormLine .Formline-datetime input[disabled] + button {
	cursor: default !important;
}