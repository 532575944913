
.GlobalMap-entity {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	bottom: 20px;
	width: 80%;
	max-width: 300px;
	z-index: 995;
	background-color: #bcebff;
    padding: 10px 20px 10px 20px;
    border-radius: 0.4rem;
    font-size: 13px;
}

.GlobalMap-entity .Entity {
	z-index: 995;
}
