
.Entity {
    position: relative;
    height: 20px;
    overflow: visible;
    margin: 2px 0px;
    line-height: 1.0;
    animation: halfFixedPopEffect 0.5s ease;
    -moz-animation: halfFixedPopEffect 0.5s ease;
    -webkit-animation: halfFixedPopEffect 0.5s ease;
    -o-animation: halfFixedPopEffect 0.5s ease;
}

.Entity {
    font-size: 15px;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 3px 5px 3px 7px;
    height: 22px;
}

.Entity:hover {
    background-color: rgba(0, 159, 227, 0.05);
}

.Entity-name {
    display:inline-block;
    width: calc(100% - 20px);
    padding-left: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
    color: #009fe3;
}

.Entity i {
    display:inline;
    font-size: 14px;
    line-height: 1.2;
    color: #009fe3;
}

.Entity-title {
    width: calc(100% - 250px);
}

/* ACTION BUTTON */

.Entity-action-buttons-wrapper {
    z-index: 500;
    position: fixed;
    bottom: 2.5%;
    width: 150px;
    max-width: 1200px;
    margin: 0 auto;
}

.Entity-action-buttons {
    position: absolute;
    width: 180px;
    bottom: 20px;
    left: -180px;
    background-color: #EEE !important;
    border: 2px solid rgb(187, 187, 187);
    padding: 2px 10px 8px 10px;
}

.Entity-action-buttons > div > button {
    width: 100%;
}
