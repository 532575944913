
.DialogSelectImage-overlay > .DialogSelectImage-content {
	width: 80% !important;
	height: 100%;
}

.DialogSelectImage-content-image {
	text-align: center;
}

.DialogSelectImage-content-close-button {
	position: absolute;
	bottom: 25px;
	right: 25px;
}

.DialogSelectImage-buttons {
	width: 100%;
	text-align: right;
	margin: 15px 0px;
}

.DialogSelectImage-buttons > div {
	margin: 0px 2px;
	display: inline;
}

.DialogSelectImage-select-button {
	text-align: center;
}