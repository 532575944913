
#InsideApp {
    width: 100%;
    height: 100%;
}

#InsideApp-content {
    float: right;
    width: calc(100% - 62px);
    height: 100%;
    overflow-y: auto;
}