
.FormAnswer {
    width: 100%;
    position: relative;
    height: 20px;
    overflow: visible;
    margin: 2px 0px;
    line-height: 1.0;
    animation: halfFixedPopEffect 0.5s ease;
    -moz-animation: halfFixedPopEffect 0.5s ease;
    -webkit-animation: halfFixedPopEffect 0.5s ease;
    -o-animation: halfFixedPopEffect 0.5s ease;
}

.FormAnswer {
    font-size: 15px;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 3px 5px 3px 7px;
    height: 22px;
}

.FormAnswer:hover {
    background-color: rgba(0, 159, 227, 0.05);
}

.FormAnswer-name {
    display: inline-block;
    width: calc(100% - 20px);
    padding-left: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
    color: #009fe3;
}

.FormAnswer i {
    display:inline;
    font-size: 14px;
    line-height: 1.2;
    color: #009fe3;
}

.FormAnswer-title {
    width: calc(100% - 250px);
    min-height: 40px;
}

.FormAnswer-content {
    white-space: initial !important;
}

.FormAnswer-question {
    background-color: lightgrey;
    padding: 10px;
    margin: 10px 0px;
}

.FormAnswer-answer {
    padding: 10px;
    margin: 10px 0px;
}