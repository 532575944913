
#DashboardGraph .vis-network, #DashboardGraph .vis-network canvas {
	position: fixed !important;
	top: 0px !important;
	left: 0px;
	width: 100% !important;
	height: 100% !important;
	z-index: 0 !important;
}

#DashboardGraph .DashboardGraph-top-bar, #DashboardGraph .DashboardGraph-top-bar div {
	z-index: 100;
}

#DashboardGraph .Loading {
	position: fixed !important;
	width: calc(100% - 64px);
	top: 0px;
	left: 64px;
}