
#EntityMap .EntityMap-map {
	z-index: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
}

.EntityMap-Loading {
	position: absolute;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 500;
}

.EntityMap-buttons {
	z-index: 20;
	height: 100px;
}

.EntityMap-buttons > button {
	margin-left: 5px;
}