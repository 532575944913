
.Note {
    margin-top: 10px;
    margin-bottom: 10px;
}

.Note .Note-content {
    background-color: rgba(255,255,255,.8);
    border: solid 1px lightgrey;
    border-radius: 10px;
    margin: 5px 0px;
    padding: 20px;
}

.Note .Note-user {
    padding: 5px 0px 0px 8px;
    vertical-align: middle;
}

.Note .Note-time {
    padding: 5px 8px 0px 0px;
    text-align: right;
    vertical-align: middle;
}

.Note .Note-delete-button {
    position: absolute;
    top: 10px;
    right: 20px;
}