.Message {
    width: 100%;
    height: 100%;
    position: relative;
}

.Message-text {
	font-style: italic;
    position: absolute;
    min-width: 90%;
    text-align: center;
    left:50%;
	top:50%;
	transform: translate(-50%,-50%);
}
